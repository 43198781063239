@charset "UTF-8";

/**********************************/
/* function */
/**********************************/
$default-padding: rem-calc(10);
$default-margin: rem-calc(10);
$submenu-bg-color-factor: -20%;

@mixin panel ($p_padding: $default-padding,
              $p_margin: $default-margin,
              $p_bg: $submenu-title-bg-color1) {
  background: $p_bg none repeat scroll 0 0;
  border-color: $medium-gray;
  border-style: solid;
  border-width: 1px;
  color: $primary-font-color;
  margin-bottom: $p_margin;
  padding: $p_padding;
}

@mixin panel_list_bg_color($p_list_bg_color: $submenu-bg-color1,
                           $p_factor: $submenu-bg-color-factor) {
  background-color: $p_list_bg_color;
  a {
    &:not(.button):hover {
      background-color: scale-color($p_list_bg_color, $lightness: $p_factor);
    }
  }
}

@mixin row_vertical_margin($top_margin: 5, $bottom_margin: 5) {
  margin-top: rem-calc($top_margin);
  margin-bottom: rem-calc($bottom_margin);
}

@mixin callout_basis($p_back_ground_color: $primary-color,
                     $p_font_size: rem-calc(20)) {
  color: $white;
  font-size: $p_font_size;
  margin: 0 0 rem-calc(5) 0;
  padding: rem-calc(12);
  line-height: 1.2;
  background-color: $p_back_ground_color;
  border-radius: rem-calc(4);
}

@mixin entry_callout($p_back_ground_color: $dark-blue,
                     $p_font_size: rem-calc(20)) {
  @include callout_basis($p_back_ground_color,
                         $p_font_size);
  font-weight: bold;
  text-align: center;
}

@mixin block_link(
  $p_padding_top: 10,
  $p_padding_bottom: 10,
  $p_padding_left: 10,
  $p_padding_right: 30) {

  a {
    position: relative;
    display: block;
    margin: 0 0 rem-calc(3) 0;
    padding: rem-calc($p_padding_top) rem-calc($p_padding_right) rem-calc($p_padding_bottom) rem-calc($p_padding_left);
    color: #FFF !important;
    text-decoration: none;
    font-weight: bold;

    &:before {
      position: absolute;
      right: rem-calc(10);
      top: 50%;
      font-family: 'FontAwesome';
      content: '\f101';
      color: $white;
      margin: rem-calc(-8) 0 0 0;
      font-size: rem-calc(16);
      line-height: 1;
    }
  }
}

@mixin block_link_no_icon(
  $p_padding_top: 10,
  $p_padding_bottom: 10,
  $p_padding_left: 10,
  $p_padding_right: 30) {

  a {
    position: relative;
    display: block;
    margin: 0 0 rem-calc(3) 0;
    padding: rem-calc($p_padding_top) rem-calc($p_padding_right) rem-calc($p_padding_bottom) rem-calc($p_padding_left);
    color: #FFF !important;
    text-decoration: none;
    font-weight: bold;
  }
}

@mixin call_out_link() {
  a {
    color: $white;
    &:hover {
      color: scale-color($white, $lightness: -20%);
      text-decoration: underline;
    }
  }
}

@mixin require_font() {
  color: red;
  font-size: rem-calc(12);
}

/**********************************/
/* general */
/**********************************/

h2, h3, h4 {
}

p {
  &.center {
    text-align: center;
  }

  a {
    position: relative;
    display: inline-block;
    transition: .3s;

    &:after {
      position: absolute;
      bottom: .3em;
      left: 0;
      content: '';
      width: 100%;
      height: 1px;
      background-color: $anchor-color;
      opacity: 0;
      transition: .3s;
    }

    &:hover::after {
      bottom: 0;
      opacity: 1;
    }
  }
}

table {
  margin: rem-calc(15) rem-calc(12) rem-calc(10) rem-calc(12);
  &.width-95 {
    width: 95%;
  }
  th {
    border-color: #fff;
    font-size: $semi-small-font-size;
  }
  td {
    border-color: #fff;
  }
  .right {
    text-align: right;
  }
  .center {
    text-align: center;
  }
}

form {
  font-size: $global-font-size;
  color: $primary-font-color;

  label {
    color: $primary-font-color;

    .title {
      font-size: rem-calc(17);
    }

    &.padding {
      padding-left: rem-calc(15);
      padding-right: rem-calc(15);
    }

    small {
      &.required {
        @include require_font();
      }
    }
  }

  p {
    &.check {
      label {
        width: 12em;
      }
    }

    span {
      &.nowrap {
        white-space: nowrap;
      }
    }
  }

  fieldset {
    legend {
      font-size: rem-calc(17);

      small {
        &.required {
          @include require_font();
        }
      }
    }
  }
}
/**********************************/
/* common */
/**********************************/
// layout
.header {
  @include grid-row(12) {
    @include breakpoint(medium) {
      .logo-space {
        @include grid-column(6);
      }

      .right-1 {
        @include grid-column(3);
      }

      .right-2 {
        @include grid-column(3);
      }
    }
  }
}

.container {
  @include grid-row(12) {
    @include breakpoint(medium) {
      .main-content {
        @include grid-column(9);
      }
      .sidebar {
        @include grid-column(3);
      }
    }
  }
}

.row {
  &.vertical-margin-25 {
    @include row_vertical_margin(25, 25);
  }

  &.vertical-margin-5 {
    @include row_vertical_margin(5, 5);
  }

  &.vertical-margin-10 {
    @include row_vertical_margin(10, 10);
  }

  &.vertical-margin-no-bottom {
    margin-top: rem-calc(25);
  }
}

.footer {
  background-color: $primary-color;
  color: $white;
  font-weight: bold;
  padding: rem-calc(20);
  text-align: center;

  a {
    color: $white;
    text-decoration: none;

    &:hover {
      color: scale-color($white, $lightness: -20%);
    }
  }
}

button, .button {
  margin: 0 0 0.5rem;
  padding: 0.8rem 1rem;
  font-size: rem-calc(14);
  font-weight: bold;
  width: 90%;
  color: $white;
  border-radius: rem-calc(4);
  &.color2 {
    background-color: $primary2-color;
  }
}

.tel-fax-color {
  color: $primary3-color;
}

img {
  &.w-250 {
    max-width: 250px;
    height: auto;
  }

  &.w-250-190 {
    width: 250px;
    height: 190px;
  }

  &.w-200 {
    max-width: 200px;
    height: auto;
  }

  &.h-187 {
    width: auto;
    max-height: 187px;
  }

  &.h-25 {
    width: auto;
    height: 25px;
  }
}

.pc { display: block !important; }
.sp { display: none !important; }

@media only screen and (max-width: 750px) {
  .pc { display: none !important; }
  .sp {
    display: block !important;
    &.top-bar-tel {
      a {
        color: $white;
      }
    }
    &.normal-tel {
      a {
        color: $primary3-color;
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

/**********************************/
/* Topbar */
/**********************************/

.top-bar {
  background-color: $primary-color;
  padding: rem-calc(4);

  li {
    &.current-page a:not(.button) {
      background-color: $topbar-link-bg-active;
    }

    &.bar-sec1 a:not(.button) {
      background-color: $primary-color;
    }

    &.bar-sec2 a:not(.button) {
      background-color: $primary2-color;
    }

    a {
      color: $white;
      font-weight: bold;
    }
  }

  .message {
    color: $warning-color;
    font-weight: bold;
    font-size: rem-calc(14);
  }
}

.top-bar-title {
  //color: $secondary-color;
  color: scale-color($primary-color, $lightness: 60%)
}

.title-bar-title-for-small {
  @extend .title-bar-title;
  color: $white;
  margin-left: rem-calc(3);
}

.title-bar-right-for-small {
  @extend .title-bar-right;
  font-weight: bold;
  color: $white;
}

.sticky {
  width: 100%;
}

#page-top {
  position: fixed;
  bottom: rem-calc(20);
  right: rem-calc(20);
  font-size: $semi-small-font-size;

  a {
    background-color: $primary-color;
    text-decoration: none;
    color: $white;
    width: rem-calc(100);
    font-weight: bold;
    padding: rem-calc(20) 0;
    text-align: center;
    display: block;
    border-radius: rem-calc(4);
    &:hover {
      background-color: $secondary-color;
    }
  }
}

/**********************************/
/* header */
/**********************************/
.header {
  padding: rem-calc(10) 0 0 0;

  p {
    line-height: 1.0;
    margin-bottom: 0.5rem;
  }
}

/**********************************/
/* sidebar */
/**********************************/
.submenu {
  margin-bottom: rem-calc(10);

  .panel {
    @include panel(
    $p_padding: rem-calc(10),
    $p_bg: $submenu-title-bg-color1
    );
    margin: rem-calc(10) 0 rem-calc(10) 0;
    border: none;

    h3 {
      margin: 0;
      font-size: rem-calc(16);
      color: $white;
      line-height: 1.2;
      text-align: center;

      @include block_link($p_padding_top: 2, $p_padding_bottom: 2);
    }

    &.title-1 {
      background-color: $submenu-bg-color1;
    }

    &.title-2 {
      background-color: $submenu-title-bg-color1;
    }
  }

  .radius {
    border-radius: rem-calc(4);
  }

  ul, ol {
    margin: 0;
    padding: 0;
    font-size: rem-calc(14);
    line-height: 1.4;
    list-style: none;
  }

  li {
    @include block_link();

    &.panel-list-1-1 {
      @include panel_list_bg_color($submenu-bg-color1, $submenu-bg-color-factor);
    }
    &.panel-list-1-2 {
      @include panel_list_bg_color($submenu-bg-color2, $submenu-bg-color-factor);
    }
    &.panel-list-1-3 {
      @include panel_list_bg_color($submenu-bg-color3, $submenu-bg-color-factor);
    }
    &.panel-list-1-4 {
      @include panel_list_bg_color($submenu-bg-color4, $submenu-bg-color-factor);
    }
    &.panel-list-1-5 {
      @include panel_list_bg_color($submenu-bg-color5, $submenu-bg-color-factor);
    }
    &.panel-list-1-6 {
      @include panel_list_bg_color($submenu-bg-color6, $submenu-bg-color-factor);
    }
    &.panel-list-1-7 {
      @include panel_list_bg_color($submenu-bg-color7, $submenu-bg-color-factor);
    }
    &.panel-list-1-8 {
      @include panel_list_bg_color($submenu-bg-color8, $submenu-bg-color-factor);
    }
    &.panel-list-1-9 {
      @include panel_list_bg_color($submenu-bg-color9, $submenu-bg-color-factor);
    }
    &.panel-list-1-10 {
      @include panel_list_bg_color($submenu-bg-color10, $submenu-bg-color-factor);
    }
    &.panel-list-1-11 {
      @include panel_list_bg_color($submenu-bg-color11, $submenu-bg-color-factor);
    }
    &.panel-list-1-12 {
      @include panel_list_bg_color($submenu-bg-color12, $submenu-bg-color-factor);
    }

    &.panel-list-1-88 {
      @include panel_list_bg_color($submenu-bg-color88, $submenu-bg-color-factor);
    }

    &.panel-list-2-1 {
      @include panel_list_bg_color($submenu-bg-color10, $submenu-bg-color-factor);
    }
    &.panel-list-2-2 {
      @include panel_list_bg_color($submenu-bg-color11, $submenu-bg-color-factor);
    }
    &.panel-list-2-3 {
      @include panel_list_bg_color($submenu-bg-color12, $submenu-bg-color-factor);
    }

    &.panel-list-3 {
      @include panel_list_bg_color($submenu-bg-color13, $submenu-bg-color-factor);
    }
  }

  .button {
    width: 100%;
  }
}

/**********************************/
/* contents */
/**********************************/

.photo-item {
  /* border-bottom: rem-calc(2) solid scale-color($primary-font-color, $lightness: 30%); */
  border-radius: rem-calc(5);
  padding-bottom: rem-calc(4);
  p {
    margin-bottom: 0;
    line-height: 1.0;
  }
}

.entry {
  h2 {
    &.call-out {
      @include entry_callout($p_font_size: rem-calc(22));
    }

    &.with-link {
      @include block_link_no_icon($p_padding_top: 0, $p_padding_bottom: 0);
    }

    &.font-emphasis-blue {
      color: $primary-blue;
      font-size: rem-calc(24);
    }

    img {
      margin: 0 rem-calc(10) 0 0;
    }
  }

  h3 {
    &.call-out-blue {
      @include entry_callout(
              $p_back_ground_color: $primary-blue,
              $p_font_size: rem-calc(20));
    }

    &.font-emphasis-blue {
      color: $primary-blue;
      font-size: rem-calc(20);
    }
  }

  h4 {
    &.font-emphasis-blue {
      color: $primary-blue;
      font-size: rem-calc(18);
    }

    &.font-emphasis-black {
      color: $primary-font-color;
      font-size: rem-calc(18);
    }
  }

  h5 {
    margin-top: rem-calc(10);
    margin-bottom: rem-calc(10);
  }

  p {
    margin: 0 rem-calc(12) rem-calc(10) rem-calc(12);

    &.inquiry {
      @include entry_callout($primary2-color);
    }

    &.blue-bold {
      color: $primary-blue;
      font-weight: bold;
    }

    .blue-bold {
      color: $primary-blue;
      font-weight: bold;
    }

    .black-bold {
      font-weight: bold;
    }
  }

  dl {
    margin: 0 rem-calc(12) rem-calc(10) rem-calc(12);

    dt {
      margin-bottom: rem-calc(5);
      margin-top: rem-calc(5);
    }
    dd {
      margin-bottom: rem-calc(8);
      margin-left: rem-calc(8);

      &.not-left-margin {
        margin-left: 0;
      }
    }
  }
  .desc-callout {
    @include callout_basis($primary-blue);

    h4 {
      font-size: rem-calc(20);
      color: $white;
      line-height: 1.2;

      @include call_out_link();
    }

    P {
      font-size: $global-font-size * 0.8;
      font-weight: bold;
      line-height: 1.0;
    }

    &.font-black {
      h4 {
        color: $primary-font-color;
      }
      p {
        color: $primary-font-color;
      }
    }
    &.font-orange {
      p {
        color: $primary-orange;
      }
    }

    .font-orange {
      color: $primary-orange;
    }

    &.light-blue {
      background-color: $primary-light-blue;
    }
  }

  .simple-callout {
    @include callout_basis($primary-blue);

    h3 {
      font-size: rem-calc(20);
      color: $white;
      line-height: 1.2;
      text-align: center;
      margin-bottom: 0;

      @include call_out_link();

      .emphasis {
        color: $yellow;
      }

      &.margin {
        margin-bottom: rem-calc(15);
      }
    }

    h4 {
      font-size: rem-calc(20);
      color: $white;
      line-height: 1.2;
      text-align: center;
      margin-bottom: 0;

      @include call_out_link();

      .emphasis {
        color: $yellow;
      }

      &.margin {
        margin: rem-calc(15) 0;
      }
    }

    p {
      font-size: rem-calc(18);
      text-align: center;
      font-weight: bold;
      margin: 0;

      &.emphasis {
        color: $primary-orange;
      }
    }

    &.light-blue {
      background-color: $primary-light-blue;

      h4 {
        color: $white;
        text-align: left;
      }

      p {
        line-height: 1.4;
        text-align: left;
        margin: rem-calc(10);
      }
    }

    &.font-black {
      h4 {
        color: $primary-font-color;
        margin: rem-calc(5);
        text-align: left;
      }

      p {
        color: $primary-font-color;
        font-size: $global-font-size * 0.8;
        font-weight: bold;
        line-height: 1.2;
      }
    }
  }

  .ranking {
    p {
      margin: rem-calc(10) 0 rem-calc(10) rem-calc(10);

      &.title {
        font-size: rem-calc(18);
        color: $primary-orange;
        font-weight: bold;

        .color-black {
          color: $black;
        }
      }

      &.comment {
        color: $primary-font-color;
        margin: 0 0 rem-calc(10) rem-calc(20);
      }
    }
  }

  .note {
    ul {
      list-style: none;
      margin: rem-calc(5) 0 rem-calc(5) rem-calc(14);

      li {
        position: relative;
        padding-left: rem-calc(22);

        &:before {
          content: '※';
          position: absolute;
          left: rem-calc(3);
        }
      }
    }

    dl {
      margin: 0 rem-calc(12) rem-calc(10) rem-calc(12);

      dt {
        position: relative;
        margin-bottom: rem-calc(4);
        padding-left: rem-calc(26);
        color: $primary-blue;

        &:before {
          content: '※';
          position: absolute;
          left: rem-calc(5);
          color: $primary-font-color;
        }
      }
      dd {
        padding-left: rem-calc(24);
        margin-bottom: rem-calc(8);
      }
    }
  }
}

.commentary {
  padding: rem-calc(10) rem-calc(10) rem-calc(10) rem-calc(10);
  border: 1px solid $border-color-light;

  h3 {
    color: $dark-blue;
    font-size: rem-calc(20);
  }
  .strong {
    font-weight: bold;
    font-size: rem-calc(18);
  }
  .color-blue {
    color: $dark-blue;
  }
}

.campaign {
  margin: rem-calc(10) 0 rem-calc(10) 0;
  p {
    line-height: 1.0;
    margin-bottom: rem-calc(8);
    color: #ff8102;
    font-weight: bold;
  }
  .strong {
    font-weight: bold;
    font-size: rem-calc(18);
  }
}

.notice {
  padding: rem-calc(10) rem-calc(10) rem-calc(10) rem-calc(10);
  background-color: $lighter-blue;
  border: 1px solid $light-blue;

  h3 {
    text-align: center;
    font-size: rem-calc(20);
  }
  h4 {
    margin-top: rem-calc(15);
    font-size: rem-calc(18);
    font-weight: bold;
    color: $dark-blue;
  }
  h5 {
    margin-top: rem-calc(15);
    font-size: rem-calc(16);
    color: $dark-blue;
  }
  .item {
    margin-bottom: rem-calc(30);
    .strong {
      font-weight: bold;
    }
  }
  .inner-campaign {
    color: #ff8102;
    margin-left: rem-calc(10);
    font-size: rem-calc(16);
  }
  .strong {
    font-weight: bold;
  }
}

.img-space {
  padding: rem-calc(10) 0 rem-calc(10) 0;

  h4 {
    font-size: rem-calc(16);
    color: $primary3-color;
    margin-top: rem-calc(5);
  }
}

.work-box {
  height: auto;
  margin-top: rem-calc(20);
  margin-bottom: rem-calc(20);
  text-align: center;

  .comment {
    border-bottom: rem-calc(1) solid $border-color-light;
    padding-bottom: rem-calc(12);
    margin-bottom: rem-calc(10);
    font-weight: bold;
  }

  .work-image {
    padding-top: rem-calc(5);
  }

  .work-text {
    height: auto;
  }

  p {
    &.blue-bold {
      color: $primary-blue;
      font-weight: bold;
    }
    &.emphasis {
      font-weight: bold;
    }
  }
}
